import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import getPlatform from "../utils/getPlatform";
import "../styles/WhatWeProvide.css";

const WhatWeProvide = () => {
  const [hovered, setHovered] = useState<number | null>(null);

  const cards = [
    {
      label: "Before Move",
      description:
        "Experience a smooth move with everything from logistics to utility registration. Stay organized with custom checklists and reminders tailored to your needs.",
      imgSrc: require("../iamges/beforeMove.png"),
    },
    {
      label: "After Move",
      description:
        "Settle in quickly with custom checklists and reminders. From changing your address to setting up your new space, we’ve got you covered.",
      imgSrc: require("../iamges/afterMove.png"),
    },
    {
      label: "Buying Home",
      description:
        "Navigate the home-buying process with ease. Get tips, tools, and checklists for affordability, mortgages, and more.",
      imgSrc: require("../iamges/buyingHome.png"),
    },
    {
      label: "Selling Home",
      description:
        "Simplify selling your home with organized checklists for paperwork, listings, and essential tasks. We make it hassle-free.",
      imgSrc: require("../iamges/sellingHome.png"),
    },
    {
      label: "Home Maintenance",
      description:
        "Keep your home in top shape with custom maintenance reminders. Stay on top of seasonal and routine tasks effortlessly.",
      imgSrc: require("../iamges/homeMaintainance.png"),
    },
  ];
  const alternateImages = [
    {
      label: "Before Move",
      imgSrc: require("../iamges/Frame 1171276041.png"),
      index: 0,
    },
    {
      label: "After Move",
      imgSrc: require("../iamges/afterMoveHover.jfif"),
      index: 1,
    },
    {
      label: "Buying Home",
      imgSrc: require("../iamges/buyingHomeHover.jfif"),
      index: 2,
    },
    {
      label: "Selling Home",
      imgSrc: require("../iamges/sellingHomeHover.jfif"),
      index: 3,
    },
    {
      label: "Home Maintenance",
      imgSrc: require("../iamges/homeMaintainanceHover.jfif"),
      index: 4,
    },
  ];

  const hoveredImage = hovered
    ? alternateImages.find((img) => img.index === hovered)?.imgSrc
    : require("../iamges/Frame 1171276041.png");

  const platform = getPlatform();

  const downloadLinks: { [key: string]: string } = {
    Android:
      "https://play.google.com/store/apps/details?id=com.arptix.homeowner",
    iOS: "https://apps.apple.com/ca/app/homeowner/id6448795356",
    Windows:
      "https://play.google.com/store/apps/details?id=com.arptix.homeowner",
    Mac: "https://apps.apple.com/ca/app/homeowner/id6448795356",
    Linux: "https://www.example.com/linux-download",
  };

  // Fallback URL if the platform is unknown
  const downloadUrl = downloadLinks[platform] || "https://www.example.com";
  return (
    <>
      <div className="container mx-auto">
        <div className="grid grid-cols-12 gap-4 mb-14 p-4">
          <div className="col-span-12 md:col-span-7">
            {cards.map((card, index) => (
              <div
                key={index}
                className="services-box"
                onMouseEnter={() => setHovered(index)}
                onMouseLeave={() => setHovered(null)}
              >
                <div className="services-header">
                  <img
                    src={card.imgSrc}
                    className="services-icon"
                    alt={card.label}
                  />
                  <h1 className="services-title ml-6 plus-jakarta-sans">
                    {card.label}
                  </h1>
                </div>
                <div className="services-description">
                  <p className="plus-jakarta-sans">{card.description}</p>

                  <button className="mt-8 py-2 px-4 action-button text-white rounded-[40px]">
                    Download our app
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="col-span-12 md:col-span-5 hidden md:block">
            <img
              src={hoveredImage}
              className="w-[100%] object-cover rounded-lg"
              alt="Service Image"
            />
          </div>
        </div>
      </div>

      {/* Get the HomeOwner mobile app today */}

      <div
        className="pt-12 relative"
        style={{
          backgroundColor: "rgba(255, 178, 0, 0.12)",
        }}
      >
        <h1 className="text-center text-[#101828] text-[36px] md:text-[44px] plus-jakarta-sans">
          Get the HomeOwner mobile app today
        </h1>

        <a
          href={downloadUrl}
          className="bg-[#FF9D00] h-[65px] w-[184px] flex justify-center items-center mx-auto rounded-[12px] mt-3"
        >
          <p className="text-white text-[36px] md:text-[28px]">It's Free!</p>
        </a>

        <p className="text-center text-[20px] md:text-[18px] text-[#667085] mt-6">
          Download and get access to all the features
        </p>

        <div className="mt-10 text-center mb-10">
          <button className="w-[7.5rem] h-[3rem] bg-[#2C3444] rounded-md ">
            <a href="https://play.google.com/store/apps/details?id=com.arptix.homeowner">
              <img
                src={require("../iamges/playstore.png")}
                style={{ width: 88 }}
                alt=""
                className="mx-auto"
              />
            </a>
          </button>
          <button className="w-[7.5rem] h-[3rem] bg-[#2C3444] rounded-md ml-7">
            <a href="https://apps.apple.com/ca/app/homeowner/id6448795356">
              <img
                style={{ width: 88 }}
                src={require("../iamges/appleStore.png")}
                alt=""
                className="mx-auto"
              />
            </a>
          </button>
        </div>

        <img
          src={require("../iamges/Group_1000004948-removebg-preview.png")}
          alt=""
          style={{
            bottom: 0,
            maxWidth: "842px",
            height: "auto",
            margin: "auto",
            width: "95%",
          }}
        />
      </div>
      <div className="mt-8 mb-14">
        <Marquee
          speed={120}
          style={{
            fontFamily: "Plus Jakarta Sans",
            fontSize: "110px",
            fontStyle: "normal",
            fontWeight: "700",
          }}
        >
          <h1 className="lg:text-[110px] text-[48px]">
            HomeOwner made it easy! &nbsp;
          </h1>
        </Marquee>
      </div>
    </>
  );
};

export default WhatWeProvide;
