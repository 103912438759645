import React from "react";
import "../styles/Card.css";
const Cards = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 md:grid-cols-2  gap-4 mx-auto max-w-5xl p-4">
      <img
        className="transform transition-transform duration-300 hover:-translate-y-2 hover:scale-105 hover:rotate-x-6"
        src={require("../iamges/Frame 11.png")}
        alt="Frame 11"
      />
      <img
        className="transform transition-transform duration-300 hover:-translate-y-2 hover:scale-105 hover:rotate-x-6"
        src={require("../iamges/Frame 1171276042.png")}
        alt="Frame 1171276042"
      />
      <img
        className="transform transition-transform duration-300 hover:-translate-y-2 hover:scale-105 hover:rotate-x-6"
        src={require("../iamges/Frame 427319018.png")}
        alt="Frame 427319018"
      />
      <img
        className="transform transition-transform duration-300 hover:-translate-y-2 hover:scale-105 hover:rotate-x-6"
        src={require("../iamges/Frame 427319019.png")}
        alt="Frame 427319018 duplicate"
      />
    </div>
  );
};

export default Cards;
