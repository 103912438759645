import React from "react";
import "../styles/HeroSection.css";

const HeroContainer = ({ children }: any) => {
  return (
    <div className="container mx-auto pt-0 md:pt-10">
      <div className="grid grid-cols-12 gap-4">
        <div
          className="col-span-12 md:col-span-7 bg-hero-div-bg rounded-3xl p-6 md:p-12 lg:p-18"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div>
            <div className="flex items-center bg-[#FF9D001F] text-white font-semibold rounded-full p-2  h-10 mb-7  w-80">
              <div
                className=" text-white font-semibold rounded-full py-1 px-3 mr-4 flex items-center "
                style={{
                  background: "#FFB200",
                }}
              >
                New
              </div>

              <a href="#" className="  " style={{ color: "#0A0A0A" }}>
                <span>Download the new app</span>
              </a>
            </div>
            <div className="mt-5">
              <h1 className="lg:text-5xl text-[26px] text-[#272D37] plus-jakarta-sans">
                Your Seamless Journey from
              </h1>
              <h1
                className="lg:text-5xl text-[26px] text-[#272D37] plus-jakarta-sans"
                style={{ fontWeight: "800" }}
              >
                Move to Maintenance
              </h1>
            </div>
            <div>
              <img
                src={require("../iamges/Vector.png")}
                alt=""
                className="w-[25rem]"
              />
            </div>
            <p className="main-section-text mt-4">
              With HomeOwner, managing your move and maintaining your home has
              never been easier. From preparing for your big day to staying on
              top of maintenance, we help you handle it all effortlessly.
            </p>
            <div className="mt-14">
              <button className="w-[7.5rem] h-[3rem] bg-[#2C3444] rounded-md ">
                <a href="https://play.google.com/store/apps/details?id=com.arptix.homeowner">
                  <img
                    src={require("../iamges/playstore.png")}
                    style={{ width: 88 }}
                    alt=""
                    className="mx-auto"
                  />
                </a>
              </button>
              <button className="w-[7.5rem] h-[3rem] bg-[#2C3444] rounded-md ml-7">
                <a href="https://apps.apple.com/ca/app/homeowner/id6448795356">
                  <img
                    style={{ width: 88 }}
                    src={require("../iamges/appleStore.png")}
                    alt=""
                    className="mx-auto"
                  />
                </a>
              </button>
            </div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-5 rounded-lg flex align-middle justify-center">
          <div className="flex items-center">
            <div className="grid grid-cols-1 relative">
              <div className="home-section-side-image">
                <img
                  className="w-[80%] mx-auto z-20 relative"
                  src={require("../iamges/Screen 3.png")}
                  alt="Screen 3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroContainer;
