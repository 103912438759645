import React from "react";

const AndMore = () => {
  return (
    <img
      className="mx-auto max-w-5xl w-full p-4 mt-4"
      src={require("../iamges/last card.png")}
      alt=""
    />
  );
};

export default AndMore;
