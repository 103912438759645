import React, { CSSProperties } from "react";
import NavBar from "./NavBar";
import HeroSection from "./HeroSection";
import InfoSection from "./InfoSection";
import BottomSection from "./BottomSection";
import Cards from "./Cards";
import AndMore from "./AndMore";
import WhatWeProvide from "./WhatWeProvide";

export type Styles = {
  [key: string]: CSSProperties;
};

function App() {
  return (
    <div className="static">
      <NavBar />
      <HeroSection />
      <InfoSection
        badge="Features"
        title={
          <>
            Explore Our{" "}
            <span style={{ fontWeight: "800" }}>Comprehensive Features</span>
          </>
        }
        description="Open a feature-rich homeowner’s account in minutes and enjoy exclusive benefits."
      />
      <Cards />
      <AndMore />
      <InfoSection badge="Services" title=" What we provide?" />
      <WhatWeProvide />
      <BottomSection />
      {/* <BottomSection /> */}
    </div>
  );
}

export default App;
