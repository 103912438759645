import "../styles/InfoSection.css";

import React from "react";

interface InfoSectionProps {
  badge: string;
  title: string | JSX.Element;
  description?: string | JSX.Element;
}

const InfoSection: React.FC<InfoSectionProps> = ({
  badge,
  title,
  description,
}) => {
  return (
    <>
      <div className="grid place-items-center text-center px-4 my-10">
        <div
          style={{ background: "#FFB2001F" }}
          className="mt-6 p-1 pl-3 pr-3 rounded-[40px]"
        >
          <h1 className="text-[#FF9D00]">{badge}</h1>
        </div>
        <h1
          className="text-4xl font-semibold mt-2 text-[#101828] plus-jakarta-sans"
          style={{ fontWeight: "500" }}
        >
          {title}
        </h1>
        <p
          className="mt-4 text-[#667085]"
          style={{
            fontWeight: 400,
            fontSize: 20,
            fontFamily: "Inter",
          }}
        >
          {description}
        </p>
      </div>
    </>
  );
};

export default InfoSection;
